






























import {Component, Prop, Vue} from "vue-property-decorator";
import {TreeModel} from "@/components/tree/TreeModel";
import TreeItem from "@/components/tree/TreeItem.vue";
import TreeForm from "@/components/tree/TreeForm.vue";
import * as _ from "lodash";

@Component({
    components: {
        'tree-item': TreeItem,
        'tree-form': TreeForm
    }
})
export default class Tree extends Vue {
    @Prop({default: () => []}) data!: TreeModel[];
    @Prop({default: true}) allowAdd!: boolean;
    @Prop({default: false}) isMultiple!: boolean;

    isShowAdd = false;
    selectedItems: any[] = [];

    onAdded(name: string) {
        this.$emit('added', {
            parentId: null,
            value: name
        });
    }

    async onItemSelected() {
        const selectedNotes = await this.getSelectedItem(this.data);
        this.$emit('itemSelected', selectedNotes);
    }

    private async getSelectedItem(items: TreeModel[]): Promise<any> {
        let selectedNodes: any = [];
        for (const treeNode of items) {
            if (treeNode.isSelected) {
                selectedNodes.push({
                    id: treeNode.id,
                    name: treeNode.label,
                    data: treeNode.data
                });
            }
            if (treeNode.children && treeNode.children.length > 0) {
                const childSelectedNodes = await this.getSelectedItem(treeNode.children);
                if (childSelectedNodes && childSelectedNodes.length > 0) {
                    selectedNodes = [...selectedNodes, ...childSelectedNodes];
                }
            }
        }
        return selectedNodes;
    }
}
