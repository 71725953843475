
























import {Component, Prop, Ref, Vue, Watch} from "vue-property-decorator";
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import {required} from "vee-validate/dist/rules";
import * as _ from 'lodash';
import i18n from "@/i18n";

extend('required', {
    ...required,
    message: (_: string, values: any) => i18n.t('validations.required', [_])
} as any);

@Component({
    components: {
        ValidationObserver,
        ValidationProvider
    }
})
export default class TreeForm extends Vue {
    @Ref('inputElement') inputElement!: any;
    @Prop({default: ''}) value!: string;
    @Prop({default: false}) isShow!: boolean;
    @Prop({default: false}) isUpdate!: boolean;

    internalValue: string = '';

    @Watch('isShow')
    onShow(value: boolean) {
        if (value) {
            setTimeout(() => {
                this.inputElement.focus();
            }, 100);
        }
        this.internalValue = _.cloneDeep(this.value);
    }

    save(invalid: boolean) {
        if (invalid) {
            return;
        }
        if (this.isUpdate) {
            this.$emit('updated', {
                oldValue: this.value,
                value: this.internalValue
            });
        } else {
            this.$emit('added', this.internalValue);
        }
        this.$emit('cancel');
    }
}
