

































































import {Component, Emit, Prop, Ref, Vue, Watch} from "vue-property-decorator";
import {TreeModel} from "@/components/tree/TreeModel";
import TreeForm from "@/components/tree/TreeForm.vue";
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import {required} from "vee-validate/dist/rules";
import i18n from "@/i18n";
import * as _ from 'lodash';

extend('required', {
    ...required,
    message: (_: string, values: any) => i18n.t('validations.required', [_])
} as any);

@Component({
    name: 'tree-item',
    components: {
        TreeForm,
        TreeItem,
        ValidationObserver,
        ValidationProvider
    }
})
export default class TreeItem extends Vue {
    @Ref('inputElement') inputElement!: any;
    @Prop() item!: TreeModel;
    @Prop({default: false}) allowAdd!: boolean;
    @Prop({default: false}) isMultiple!: boolean;

    isEdit = false;
    isShowAdd = false;

    @Emit('itemSelected')
    emitItemSelected(item: TreeModel) {
        return {
            id: item.id,
            label: item.label,
            data: item.data
        };
    }

    @Watch('item.children', {deep: true})
    onChildrenChange() {
        const childrenLength = this.item.children ? this.item.children.length : 0;
        this.item.isSelected = _.countBy(this.item.children, 'isSelected').true === childrenLength;
    }

    get isOpen() {
        return this.item.isOpen;
    }

    set isOpen(value: any) {
        this.item.isOpen = value;
    }

    get isFolder() {
        return this.item.children && this.item.children.length;
    }

    get isSelected() {
        return this.item.isSelected;
    }

    get allowDelete() {
        return this.item.allowDelete;
    }

    onAdded(name: string) {
        this.$emit('added', {
            parentId: this.item.id,
            value: name
        });
    }

    onUpdated(data: any) {
        const item = Object.assign(data, {id: this.item.id, parentId: this.item.parentId, data: this.item.data});
        this.$emit('updated', item);
    }

    onChildItemSelected(item: TreeModel) {
        const childCount = this.item.children ? this.item.children.length : 0;
        const selectedChildCount = _.countBy(this.item.children, 'isSelected');
        this.item.isSelected = selectedChildCount.true === childCount;
        this.emitItemSelected(item);
    }

    toggle() {
        if (this.isFolder) {
            this.isOpen = !this.isOpen
        }
    }

    addChildren() {
        this.isOpen = true;
        this.isShowAdd = true;
    }

    changeSelectStatus() {
        this.item.isSelected = !this.item.isSelected;
        if (this.item.children && this.item.children.length > 0) {
            _.each(this.item.children, (item: TreeModel) => {
                item.isSelected = this.item.isSelected;
            });
        }
        this.emitItemSelected(this.item);
    }

    removeItem() {
        this.$emit('removeItem', this.item);
    }

    save(invalid: boolean) {
        if (invalid) {
            return;
        }
    }
}
